import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/cn';
import type { BaseInstance } from '@pigello/pigello-matrix';
import type { Path } from 'react-hook-form';
import type { FieldProps } from '../types';
import { numberregexp, parseFieldValue } from '../utils';
import { DescriptionTooltip } from './description-tooltip';

export function NumberField<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  className,
  horizontal,
  disabled,
  canHandleField,
}: FieldProps<Instance>) {
  return (
    <FormField
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
        maxLength: {
          value: 20,
          message: 'Fältet får inte vara längre än 20 tecken',
        },
      }}
      control={formField.control}
      name={name as Path<Instance>}
      render={({ field }) => {
        if (horizontal) {
          return (
            <FormItem
              className={cn(
                'flex items-center justify-between space-y-0',
                className
              )}
            >
              <div className='w-1/2 shrink-0 '>
                <div className='flex items-center'>
                  <FormLabel>
                    {label ?? formField.label}{' '}
                    {formField.required && <span>*</span>}
                  </FormLabel>
                  {(description || formField.description) && (
                    <DescriptionTooltip
                      description={description ?? formField.description}
                    />
                  )}
                </div>

                <FormMessage />
              </div>
              <FormControl>
                <Input
                  {...field}
                  onChange={(e) =>
                    field.onChange(
                      parseFieldValue({
                        newValue: e.target.value,
                        prevValue: field.value,
                        regex: numberregexp,
                        transformValue(value) {
                          if (value === '0-') return '-';
                          if (value === '-') return value;
                          return value;
                        },
                      })
                    )
                  }
                  value={field.value ?? ''}
                  placeholder={placeholder ?? formField.label}
                  disabled={disabled ?? field.disabled ?? !canHandleField}
                />
              </FormControl>
            </FormItem>
          );
        } else {
          return (
            <FormItem className={className}>
              <div className='flex items-center'>
                <FormLabel>
                  {label ?? formField.label}{' '}
                  {formField.required && <span>*</span>}
                </FormLabel>
                {(description || formField.description) && (
                  <DescriptionTooltip
                    description={description ?? formField.description}
                  />
                )}
              </div>
              <FormControl>
                <Input
                  {...field}
                  onChange={(e) =>
                    field.onChange(
                      parseFieldValue({
                        newValue: e.target.value,
                        prevValue: field.value,
                        regex: numberregexp,
                        transformValue(value) {
                          if (value === '0-') return '-';
                          if (value === '-') return value;
                          return value;
                        },
                      })
                    )
                  }
                  value={field.value ?? ''}
                  placeholder={placeholder ?? formField.label}
                  disabled={disabled ?? field.disabled ?? !canHandleField}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }
      }}
    />
  );
}

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/cn';
import type { BaseInstance } from '@pigello/pigello-matrix';
import type { Path } from 'react-hook-form';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';

export function TextField<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  horizontal,
  className,
  disabled,
  overrideRequired,
  canHandleField,
  overrideMaxLength,
}: FieldProps<Instance>) {
  if (horizontal) {
    return (
      <FormField
        rules={{
          required:
            (overrideRequired != null
              ? overrideRequired
              : formField.required) && 'Detta fält är obligatoriskt',
        }}
        control={formField.control}
        name={name as Path<Instance>}
        render={({ field }) => (
          <FormItem
            className={cn(
              'flex items-center justify-between space-y-0',
              className
            )}
          >
            <div className='w-1/2 shrink-0'>
              <div className='flex items-center'>
                <FormLabel>
                  {label ?? formField.label}
                  {(overrideRequired != null
                    ? overrideRequired
                    : formField.required) && <span>*</span>}
                </FormLabel>

                {(description || formField.description) && (
                  <DescriptionTooltip
                    description={description ?? formField.description}
                  />
                )}
              </div>

              <FormMessage />
            </div>
            <FormControl>
              <Input
                {...field}
                value={field.value ?? ''}
                placeholder={
                  placeholder ?? (label as string) ?? formField.label
                }
                disabled={disabled ?? field.disabled ?? !canHandleField}
                maxLength={overrideMaxLength}
              />
            </FormControl>
          </FormItem>
        )}
      />
    );
  }
  return (
    <FormField
      control={formField.control}
      rules={{
        required:
          (overrideRequired != null ? overrideRequired : formField.required) &&
          'Detta fält är obligatoriskt',
      }}
      name={name as Path<Instance>}
      render={({ field }) => (
        <FormItem className={className}>
          <div className='flex items-center'>
            <FormLabel>
              {label ?? formField.label}{' '}
              {(overrideRequired != null
                ? overrideRequired
                : formField.required) && <span>*</span>}
            </FormLabel>
            {(description || formField.description) && (
              <DescriptionTooltip
                description={description ?? formField.description}
              />
            )}
          </div>
          <FormControl>
            <Input
              {...field}
              value={field.value ?? ''}
              placeholder={placeholder ?? (label as string) ?? formField.label}
              disabled={disabled ?? field.disabled ?? !canHandleField}
              maxLength={overrideMaxLength}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

import type { BaseInstance } from '@pigello/pigello-matrix';
import type { FieldProps } from '../types';

import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/cn';
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import { useRef, useState } from 'react';
import type { Path } from 'react-hook-form';
import { DescriptionTooltip } from './description-tooltip';

export function ChoiceField<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  label,
  placeholder,
  description,
  horizontal,
  className,
  disabled,
  canHandleField,
  overrideRequired,
  choiceFieldSortingDisabled,
}: FieldProps<Instance>) {
  const choiceField = formField.config?.fields[formField.name];
  const [open, setOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement | null>(null);
  if (!('choices' in choiceField)) {
    return null;
  }

  const handleValue = (value: unknown) => {
    if (typeof value === 'string') {
      if (
        choiceField.choices?.find((choice) => choice.v.toString() == value) ===
        undefined
      ) {
        return '';
      }
      return value;
    }
    if (typeof value === 'number') {
      if (
        choiceField.choices?.find((choice) => choice.v == value) === undefined
      ) {
        return '';
      }
      return value.toString();
    }
    if (value === null) {
      return '';
    }
    if (Number.isNaN(value)) {
      return '';
    }
    return '';
  };

  const isNullable = formField.config.fields?.[name].nullable;

  const isNumberChoice = typeof choiceField.choices?.[0]?.v === 'number';
  return (
    <FormField
      control={formField.control}
      rules={{
        required:
          (overrideRequired ?? formField.required) &&
          'Detta fält är obligatoriskt',
      }}
      name={name as Path<Instance>}
      render={({ field }) => {
        const onChange = (e: string | number) => {
          if (isNumberChoice && typeof e === 'string') {
            try {
              field.onChange(parseInt(e, 10));
            } catch {
              field.onChange(e);
            }
          } else {
            field.onChange(e.toString());
          }
        };

        const clearField = () => {
          field.onChange(null);
        };
        const sortFn = (
          a: { v: string | number; d: string },
          b: { v: string | number; d: string }
        ) => {
          if (choiceFieldSortingDisabled) {
            return 0;
          }
          if (typeof a.v === 'string' && typeof b.v === 'string') {
            return a.d.localeCompare(b.d);
          }
          return Number(a.v) - Number(b.v);
        };
        return (
          <FormItem
            className={cn(
              'flex items-center justify-start ',
              !horizontal && 'flex w-full flex-col items-start',
              horizontal && 'space-y-0',
              className
            )}
          >
            <div className={cn(horizontal ? 'w-1/2 shrink-0 ' : 'w-full')}>
              <div className={cn('flex items-start')}>
                {(label || formField?.label) && (
                  <FormLabel
                    className={cn(
                      disabled || (field.disabled && 'pointer-events-none')
                    )}
                  >
                    {label ?? formField.label}{' '}
                    {(overrideRequired || formField.required) && '*'}
                  </FormLabel>
                )}
                {(description || formField.description) && (
                  <DescriptionTooltip
                    description={description ?? formField.description}
                  />
                )}
              </div>
              {horizontal && <FormMessage />}
            </div>
            <div className='flex w-full items-center'>
              <Popover modal={true} open={open} onOpenChange={setOpen}>
                <FormControl>
                  <PopoverTrigger asChild ref={btnRef}>
                    <Button
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                      variant='outline'
                      ref={btnRef}
                      role='listbox'
                      className={cn(
                        'h-10 w-full justify-between px-3 text-left font-normal shadow-input',
                        !handleValue(field.value) && 'text-muted-foreground'
                      )}
                    >
                      <div className='line-clamp-1 py-px'>
                        {choiceField.choices?.find(
                          (choice) => choice.v == field.value
                        )?.d ??
                          placeholder ??
                          label ??
                          formField.label}
                      </div>
                      <div className='flex items-center'>
                        {isNullable && handleValue(field.value) !== '' && (
                          <span
                            title='Rensa fältet'
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                clearField();
                              }
                            }}
                            role='button'
                            tabIndex={0}
                            onClick={(e) => {
                              e.stopPropagation();
                              clearField();
                            }}
                          >
                            <XMarkIcon className='size-4 opacity-50 transition-opacity hover:opacity-100' />
                          </span>
                        )}
                        <ChevronUpDownIcon className='ml-2 size-4 shrink-0 opacity-50' />
                      </div>
                    </Button>
                  </PopoverTrigger>
                </FormControl>
                <PopoverContent
                  style={{ width: btnRef.current?.offsetWidth ?? undefined }}
                  className='p-0 pb-1'
                >
                  <Command>
                    <CommandInput
                      placeholder={placeholder ?? formField.label}
                    />

                    <CommandList>
                      <CommandEmpty>Inga resultat hittades</CommandEmpty>
                      <CommandGroup className='max-h-60 w-auto overflow-y-scroll'>
                        {choiceField.choices?.sort(sortFn)?.map((choice) => (
                          <CommandItem
                            keywords={[choice?.d, choice?.d?.toUpperCase()]}
                            key={choice.v}
                            value={choice.v.toString()}
                            onSelect={() => {
                              onChange(choice.v.toString());
                              setOpen(false);
                            }}
                          >
                            <CheckIcon
                              className={cn(
                                'mr-2 size-4',
                                choice.v === field.value
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              )}
                            />
                            {choice.d}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
            {!horizontal && <FormMessage />}
          </FormItem>
        );
      }}
    />
  );
}

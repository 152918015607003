import SelectTable from '@/components/data-table/custom-tables/select-table';
import { getSelectItemFromModelName } from '@/components/data-table/custom-tables/select-table/select-items/relation-item-map';
import { Icons } from '@/components/icons';
import TableFilters from '@/components/table-filters';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import { usePerms } from '@/hooks/usePerms';
import { cn } from '@/lib/cn';
import { getConfig } from '@/lib/get-config';
import { useGetInfiniteList, useGetList } from '@/requests/hooks';
import {
  ArrowsPointingOutIcon,
  CheckIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import type { ModelName } from '@pigello/pigello-matrix';
import { type BaseInstance } from '@pigello/pigello-matrix';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, type Path } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import type { FieldProps } from '../types';
import { getCustomDisplayName } from '../utils';
import { DescriptionTooltip } from './description-tooltip';
export default function ManyRelationalField<
  Instance extends BaseInstance = BaseInstance,
>({
  formField,
  name,
  placeholder,
  label,
  description,
  className,
  horizontal,
  disabled,
  filters,
  order,
  canHandleField,
}: FieldProps<Instance>) {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const btnRef = useRef<HTMLButtonElement>(null);
  const debouncedSearch = useDebounceCallback(setSearch, 300);
  const relationConfigName = formField.config?.fields[formField.name];
  const [previewData, setPreviewData] = useState<Instance[]>();
  const [initialIds, setInitialIds] = useState<string[]>();
  const { getValues } = useFormContext();
  // const isNullable = formField.config.fields?.[name].nullable;
  if (!('relationConfig' in relationConfigName)) {
    throw new Error('relationConfig is not in relationConfigName');
  }
  const relationConfigModelName = relationConfigName.relationConfig;

  const { canView: canViewModel } = usePerms<Instance>(relationConfigModelName);
  const relationConfig = getConfig<ModelName, Instance>(
    relationConfigModelName,
    true
  );

  const { data, isFetching, hasNextPage, fetchNextPage, error } =
    useGetInfiniteList<Instance>({
      modelName: relationConfigModelName,
      enabled: canViewModel,
      queryParams: {
        order: order,
        filters: {
          ...(!!initialIds?.length && {
            id: { '__in!': initialIds?.join(',') },
          }),
          archived: { __in: false },
          ...filters,
        },
        page: 1,
        pageSize: 50,
        search,
      },
      nested: getSelectItemFromModelName(relationConfigModelName)?.nested,
    });

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: !!error,
    rootMargin: '0px 0px 100px 0px',
  });

  const renderDisplayName = (instance: Instance | undefined) => {
    if (!instance) return '';

    const customDisplay = getCustomDisplayName(
      relationConfig?.modelName,
      instance
    );

    if (customDisplay) return customDisplay;

    const displayKey = relationConfig?.displayFieldName;

    if (displayKey && instance[displayKey as keyof Instance] != null) {
      return instance[displayKey as keyof Instance]?.toString();
    }

    if (instance['customId'] != null) return instance['customId'];

    return instance['id'];
  };

  const { data: instances, isLoading: isLoadingPreviewInstances } =
    useGetList<Instance>({
      queryParams: {
        filters: {
          id: { __in: initialIds?.join(',') },
          archived: { __in: false },
          ...filters,
        },
      },
      modelName: relationConfigModelName,
      enabled: !!initialIds?.length && canViewModel,
      nested: getSelectItemFromModelName(relationConfigModelName)?.nested,
      ...(previewData && {
        initialData: {
          list: previewData,
          meta: { page: 0, page_amount: 0, page_size: 0, total_amount: 0 },
        },
      }),
    });

  const initIds = (getValues(name as string) as BaseInstance[] | null)?.map(
    (inst) => inst.id
  );
  useEffect(() => {
    if (initIds?.length && initIds.length > 0 && !initialIds) {
      setInitialIds(initIds);
    }
  }, [getValues, initialIds, name, initIds]);

  const options = useMemo(() => {
    const infiniteOptions = data?.pages.flatMap((page) => page.list) ?? [];
    if (instances?.list && instances?.list.length > 0) {
      return [...(instances.list ?? []), ...infiniteOptions];
    }
    return infiniteOptions;
  }, [data?.pages, instances?.list]);

  if (!canViewModel) {
    return (
      <div
        className={cn(
          'flex items-center justify-start gap-2',
          !horizontal && 'flex w-full flex-col items-start',
          className
        )}
      >
        <Label className={cn(formField.required && "after:content-['*']")}>
          {label ?? formField.label}{' '}
        </Label>
        <Input disabled placeholder='Ej behörighet att se detta fält' />
      </div>
    );
  }

  return (
    <FormField
      control={formField.control}
      name={name as Path<Instance>}
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
      }}
      render={({ field }) => {
        const handleSelected = (id: string) => {
          const isAlreadySelected = field.value?.find?.(
            (inst: BaseInstance) => inst.id === id
          );
          const newData = isAlreadySelected
            ? field.value?.filter?.((inst: BaseInstance) => inst.id !== id) || []
            : [...(field.value ?? []), { id }];
          field.onChange(newData);
          const newPreviewData = options.filter((opt) =>
            newData.find((inst: BaseInstance) => inst.id === opt.id)
          );
          setPreviewData(newPreviewData);
        };
        const handleRemove = (id: string) => {
          field.onChange(
            field.value?.filter?.((inst: BaseInstance) => inst.id !== id) || []
          );
        };
        return (
          <>
            <Dialog open={expanded} onOpenChange={setExpanded}>
              <DialogContent
                size='lg'
                className='p-0 shadow-2xl'
                overlayClassName='bg-gradient-to-t from-black/0 to-black/0 backdrop-blur-0'
              >
                <DialogHeader>
                  <DialogTitle>
                    Välj {formField.label.toLowerCase()}
                  </DialogTitle>
                  <Button
                    variant={'secondary'}
                    size={'icon-sm'}
                    onClick={() => setExpanded(false)}
                  >
                    <XMarkIcon className='size-4' />
                  </Button>
                </DialogHeader>
                <SelectTable<Instance>
                  TableFilters={TableFilters}
                  queryParams={{
                    filters: filters,
                  }}
                  modelName={relationConfigModelName}
                  tableId={`${relationConfigModelName}-relation-field-table`}
                  selected={
                    field.value?.map((inst: BaseInstance) => inst.id) ?? []
                  }
                  onSelected={() => undefined}
                  onSelectedInstance={(instance) => {
                    const isAlreadySelected = field.value?.find?.(
                      (inst: BaseInstance) => inst.id === instance.id
                    );
                    const newData = isAlreadySelected
                      ? field.value?.filter?.(
                          (inst: BaseInstance) => inst.id !== instance.id
                        ) || []
                      : [...(field.value ?? []), { id: instance.id }];
                    field.onChange(newData);
                    const newPreviewData = options
                      .filter(
                        (opt) =>
                          newData.find(
                            (inst: BaseInstance) => inst.id === opt.id
                          ) && opt.id !== instance.id
                      )
                      .concat(instance);
                    setPreviewData(newPreviewData);
                  }}
                />
                <DialogFooter>
                  <Button
                    variant={'default'}
                    type='button'
                    onClick={() => setExpanded(false)}
                  >
                    Stäng
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <FormItem
              className={cn(
                'flex items-center justify-start',
                !horizontal && 'flex w-full flex-col items-start',
                horizontal && 'space-y-0',
                className
              )}
            >
              <div className={cn(horizontal ? 'w-1/2 shrink-0' : 'w-full')}>
                <div className={cn('flex items-start')}>
                  <FormLabel
                    className={cn(formField.required && "after:content-['*']")}
                  >
                    {label ?? formField.label}{' '}
                  </FormLabel>
                  {(description || formField.description) && (
                    <DescriptionTooltip
                      description={description ?? formField.description}
                    />
                  )}
                </div>
                {horizontal && <FormMessage />}
              </div>
              <div className='flex w-full items-center gap-2'>
                <Popover
                  modal={true}
                  open={open}
                  onOpenChange={(isOpen) => {
                    setSearch('');
                    setOpen(isOpen);
                    setExpanded(false);
                    setPreviewData(undefined);
                  }}
                >
                  <FormControl>
                    <PopoverTrigger asChild>
                      <Button
                        ref={btnRef}
                        disabled={disabled ?? field.disabled ?? !canHandleField}
                        variant='outline'
                        role='listbox'
                        className={cn(
                          'min-h-10 w-full items-center justify-between px-3 py-0 leading-relaxed shadow-input hover:bg-accent/30 active:scale-100'
                        )}
                      >
                        <div className='flex max-h-40 flex-wrap items-center gap-1 overflow-auto py-2'>
                          {isLoadingPreviewInstances
                            ? 'Laddar...'
                            : field.value?.length
                              ? field.value?.map((inst: Instance) => (
                                  <motion.div
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    key={inst.id}
                                  >
                                    <Badge
                                      variant={'light-gray'}
                                      className='rounded-full bg-gray-200 text-foreground transition-all hover:bg-gray-200/80'
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      {options?.find((o) => o.id === inst.id)
                                        ? renderDisplayName(
                                            options?.find(
                                              (o) => o.id === inst.id
                                            )
                                          )
                                        : 'Laddar...'}
                                      <XMarkIcon
                                        title='Rensa fältet'
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            handleRemove(inst.id);
                                          }
                                        }}
                                        role='button'
                                        tabIndex={0}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemove(inst.id);
                                        }}
                                        className='ml-1 size-4 transition-transform hover:rotate-90'
                                      />
                                    </Badge>
                                  </motion.div>
                                ))
                              : (placeholder ?? `Välj ${formField.label}`)}
                        </div>
                        <ChevronUpDownIcon className='size-4 shrink-0 opacity-50' />
                      </Button>
                    </PopoverTrigger>
                  </FormControl>
                  <PopoverContent
                    align='center'
                    style={{
                      width: btnRef.current?.offsetWidth ?? 0,
                    }}
                    className={
                      'p-0 pb-1 shadow-[rgba(0,0,0,0.12)_0px_4px_30px,rgba(0,0,0,0.04)_0px_3px_17px,rgba(0,0,0,0.04)_0px_2px_8px,rgba(0,0,0,0.04)_0px_1px_1px] transition-all duration-300 ease-in-out'
                    }
                  >
                    <Command shouldFilter={false} loop={false}>
                      <CommandInput
                        placeholder={
                          placeholder ??
                          `Sök efter ${formField.label.toLowerCase()}`
                        }
                        onValueChange={(value) => debouncedSearch(value.trim())}
                        isLoading={isFetching}
                        expand={
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setOpen(false);
                                    setExpanded((expanded) => !expanded);
                                  }
                                }}
                                variant='ghost'
                                size={'icon-sm'}
                                className='group'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpen(false);
                                  setExpanded((expanded) => !expanded);
                                }}
                              >
                                <ArrowsPointingOutIcon className='size-4 shrink-0 opacity-50 transition-opacity group-hover:opacity-100' />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              Visa avancerade sökalternativ
                            </TooltipContent>
                          </Tooltip>
                        }
                      />
                      <CommandList>
                        {!isFetching && (
                          <CommandEmpty className='flex items-center justify-center gap-2 font-medium text-muted-foreground'>
                            <MagnifyingGlassIcon className='size-6 opacity-50' />
                            Inga resultat hittades
                          </CommandEmpty>
                        )}
                        <CommandGroup
                          className={cn('max-h-60 w-auto overflow-y-scroll')}
                        >
                          {options.map((option) => (
                            <CommandItem
                              keywords={[option.id]}
                              key={option.id}
                              value={option.id}
                              onSelect={(val) => {
                                handleSelected(val);
                              }}
                            >
                              <div className='grid w-full gap-1'>
                                <div className='flex'>
                                  <CheckIcon
                                    className={cn(
                                      'mr-2 size-4',
                                      field.value?.find?.(
                                        (inst: BaseInstance) =>
                                          inst.id === option.id
                                      )
                                        ? 'opacity-100'
                                        : 'opacity-0'
                                    )}
                                  />
                                  <div className='flex w-full items-center justify-between'>
                                    {renderDisplayName(option)}{' '}
                                    <span>
                                      {
                                        getSelectItemFromModelName(
                                          relationConfigModelName
                                        )?.renderItem(option).badge
                                      }
                                    </span>
                                  </div>
                                </div>
                                <div className='ml-6 whitespace-nowrap text-xs text-muted-foreground'>
                                  {
                                    getSelectItemFromModelName(
                                      relationConfigModelName
                                    )?.renderItem(option, true).descriptionItems
                                  }
                                </div>
                              </div>
                            </CommandItem>
                          ))}

                          {(isFetching || hasNextPage) && (
                            <div
                              ref={sentryRef}
                              className='flex size-full items-center justify-center'
                            >
                              <Icons.loader className='my-4 size-6 animate-spin' />
                            </div>
                          )}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
              {!horizontal && <FormMessage />}
            </FormItem>
          </>
        );
      }}
    />
  );
}
